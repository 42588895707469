import type { ComponentType } from "react"

const negativeGapPrefix = "Gap:" // Prefix the layer name with this to set the gap size

export function NegativeGapVertical(Component): ComponentType {
    return (props) => applyNegativeGap(Component, props, true)
}

export function NegativeGapHorizontal(Component): ComponentType {
    return (props) => applyNegativeGap(Component, props, false)
}

// Function for applying negative gap to an element.
function applyNegativeGap(Component, props, isVertical) {
    const name = props["data-framer-name"]

    if (name?.startsWith(negativeGapPrefix)) {
        const id =
            props.id || "a" + Math.floor(Math.random() * 10000000).toString()
        const gap = name.replace(new RegExp("^" + negativeGapPrefix), "").trim()

        return (
            <div style={{ display: "contents" }}>
                <Component
                    {...props}
                    id={id}
                    style={{
                        ...props.style,
                        gap: 0,
                    }}
                />
                <style>{`#${id} > *:not(:first-child) { margin-${
                    isVertical ? "top" : "left"
                }: ${gap}px; }`}</style>
            </div>
        )
    }

    return <Component {...props} />
}
